import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import logo from '../img/logo.png';

import { colors, sizes } from '../constants';

const height = 60;

const styles = theme => ({
  root: {
    color: colors.secondaryText,
    height,
    lineHeight: height + 'px',
    margin: height / 4 + 'px 0',
    textAlign: 'center',
    userSelect: 'none',
  },
  image: {
    height: height + 'px',
    width: 'auto',
  },
  title: {
    color: colors.primaryText,
    display: 'inline-block',
    verticalAlign: 'top',
    fontSize: height * 0.75 + 'px',
    fontFamily: 'Barlow Condensed',
    fontWeight: 300,
    textTransform: 'uppercase',
  },
  [sizes.sm]: {
    image: {
      height: height + 'px',
    },
    title: { display: 'none' }
  }
});

const Logo = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <img alt="Gradient Health Logo" src={logo} className={classes.image} />
      <div className={classes.title}>Gradient Health</div>
    </div>
  );
};

export default withStyles(styles)(Logo);
