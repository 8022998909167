import axios from 'axios';

const baseURL = process.env.REACT_APP_MODEL_URL;

export const post = (path, headers, body, cb) => {
  axios.post(baseURL + '/' + path, body, { headers })
    .then((response) => {
      cb(response, true);
    })
    .catch((error) => {
      cb(error, false);
    });
};

export const get = (path, cb) => {
  axios.get(baseURL + '/' + path)
    .then((response) => {
      cb(response, true);
    })
    .catch((error) => {
      cb(error, false);
    });
};