import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

const styles = theme => ({
  success: {
    color: 'white',
    backgroundColor: green[600],
  },
  error: {
    color: 'white',
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Notification = (props) => {
  const { classes, state, update } = props;
  const { notification } = state;
  const isOpen = state.notification !== null;
  const message = isOpen ? state.notification[0] : '';
  const handleClose = () => update('notification', null);

  // styling
  const success = isOpen ? state.notification[1] : false;
  const variant = success ? 'success' : 'error';
  const Icon = success ? CheckIcon : ErrorIcon;

  return (
    isOpen && (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      />
      </Snackbar >
    )
  );
};

export default withStyles(styles)(Notification);
