import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { colors } from '../constants';


const styles = {
  root: {
    flexGrow: 1,
  },
  linearColorPrimary: {
    backgroundColor: 'transparent',
    height: 3,
  },
  linearBarColorPrimary: {
    background: colors.gradient,
  },
};

const totalTime = 20; // seconds
const fps = 30; // how many steps to update at
const steps = totalTime * fps;
const interval = 1000 / fps;
let then;
let delta;

class ProgressBar extends React.Component {
  state = {
    completed: 0,
    done: false,
  };

  componentDidMount() {
    this.timer = requestAnimationFrame(this.progress);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.timer);
  }

  progress = (now) => {
    const { update } = this.props;
    const { completed } = this.state;
    if (completed >= 100) {
      update('notification', ['Processing taking a bit longer than usual.', false]);
      cancelAnimationFrame(this.timer);
    } else {
      if (!then) {
        then = now;
      }
      this.timer = requestAnimationFrame(this.progress);
      delta = now - then;
      if (delta > interval) {
        this.setState((prevState) => {
          return { completed: prevState.completed + (100 / steps) }
        });
        then = now - (delta % interval);
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <LinearProgress
          classes={{
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary,
          }}
          variant="determinate"
          value={this.state.completed} />
      </div>
    );
  }
}

export default withStyles(styles)(ProgressBar);
