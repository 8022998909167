import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import Dropzone from 'react-dropzone'
import fileType from 'file-type';
import uploaderIcon from '../../img/upload.svg';
import { withStyles } from '@material-ui/core/styles';
import { uuid } from '../../constants';

import UploadStyles from './UploadStyles';

const onDrop = (update, request, files) => {
  if (files.length === 1) {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const data = reader.result;
      const ft = fileType(new Uint8Array(data));
      if (ft === null) {
        update('notification', ['Empty file.', false]);
      }
      else if (ft.ext === 'dcm') {
        update('imageData', {
          id: uuid(),
          original: data,
          processed: null,
          file,
          type: ft.ext,
        });
        request('process', file);
      } else {
        update('notification', [`Invalid file type: ${ft.ext}`, false]);
      }
    }
  }
}

const UploadCard = (props) => {
  const { classes, update, request, compact } = props;
  const rootClass = compact ? classes.uploaderCompact : classes.uploader;
  return (
    <Card>
      <CardActionArea classes={{ root: classes.root }}>
        <Dropzone
          multiple={false}
          maxSize={36700160} // 35 MB
          onDrop={(files) => onDrop(update, request, files)}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps()}
              className={`${rootClass} ${isDragActive && classes.uploaderActive}`}>
              <input {...getInputProps()} />
              <div className={classes.centered}>
                {!compact && <img className={classes.img} src={uploaderIcon} />}
                <div className={classes.text}>
                  {!isDragActive ?
                    'Drag and drop or click to upload.'
                    : 'Drop image to upload.'}
                  <div className={classes.details}>
                    DICOM format (35 MB max)
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone >
        <div className={classes.examples}>
          {/* to put example DICOMS */}
        </div>
      </CardActionArea>
    </Card >
  );
};

export default withStyles(UploadStyles)(UploadCard);
