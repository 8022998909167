import { fade, lighten } from '@material-ui/core/styles/colorManipulator';
import { colors, sizes } from '../../constants';

const styles = () => {
  const activeTarget = {
    background: fade(colors.secondary, 0.5),
    borderColor: colors.gradientStart,
  };
  const img = {
    width: '200px',
    opacity: 0.9,
    filter: 'invert(100%)',
  };
  const animationSpeed = 5;
  const animation = {
    '-webkit-animation': `spin ${animationSpeed}s linear infinite`,
    '-moz-animation': `spin ${animationSpeed}s linear infinite`,
    'animation': `spin ${animationSpeed}s linear infinite`
  }
  const keyframes = {
    '@keyframes spin': {
      '100%': {
        '-webkit-transform': 'rotate(-360deg)', transform: 'rotate(-360deg)',
      }
    },
  }
  const uploader = {
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
    textAlign: 'center',
    border: '2px solid ' + colors.primary,
    borderStyle: 'dashed',
    padding: '60px 0',
    cursor: 'pointer',
    fontSize: '35px',
    transition: 'background 0.3s',
    outline: 0,
    '&:hover': {
      ...activeTarget,
    },
  };
  return {
    root: {
      fontFamily: 'Lato',
    },
    examples: {
      width: '100%',
      height: '40%',
    },
    fileLink: {
      color: colors.secondaryText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    uploader,
    uploaderCompact: {
      ...uploader,
      padding: '15px 0',
      fontSize: '28px',
    },
    uploaderActive: {
      ...activeTarget,
    },
    processing: {
      cursor: 'auto',
      ...activeTarget,
    },
    img,
    processingImg: {
      ...img,
      ...animation,
      width: '100px',
    },
    ...keyframes,
    text: {
      fontSize: '100%',
      margin: '0 auto',
      padding: '0 20px',
      position: 'relative',
      color: colors.primaryText,

    },
    details: {
      fontSize: '55%',
      margin: '10px auto',
      color: lighten(colors.primary, 0.2)
    },
    [sizes.sm]: {
      img: {
        width: '120px',
      },
      uploader: {
        fontSize: '25px',
        padding: '15px 0',
      },
      uploaderCompact: {
        fontSize: '22px',
      },
    }
  }
};

export default styles;