export const colors = {
  backgroundContrast: '#121a24',
  background: '#1b2430',
  primary: '#4A86ED',
  secondary: '#314CEE',
  gradientStart: '#25E7FE',
  gradientEnd: '#5714B2',
  cellDefault: '#273142',
  cellActive: '#2e3e59',
  star: '#ffe766',
  primaryText: '#ffffff',
  secondaryText: '#79A5ED',
  statusUnviewed: '#f1354c',
  statusInProgress: '#FF9011',
  statusComplete: '#21D37F',
  rejectDelete: '#F1354C',
  gradient: 'linear-gradient(120deg, #71e6fb 20%,#367fe7 50%,#4536d9 80%)'
};

export const theme = {
  palette: {
    type: 'dark',
    primary: {
      main: colors.background,
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Lato'",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 900,
  },
};

// screen sizes
export const sizes = {
  md: '@media (max-width:1200px)',
  sm: '@media (max-width:650px)'
}

export const uuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const width = 550;