import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/lab/Slider';

import DWV from './DWV';

import { colors, width } from '../../constants';

const actionColor = lighten(colors.secondary, 0.3);

const styles = theme => ({
  card: {
    marginBottom: '20px',
  },
  viewContainer: {
    position: "relative",
    height: width,
    width: width,
  },
  empty: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'black'
  },
  button: {
    color: actionColor,
  },
  slider: {
    padding: '22px 0',
    margin: '0 25px',
  },
  sliderTrack: {
    background: colors.gradient,
  },
  sliderThumb: {
    background: actionColor
  },
  sliderTrackAfter: {
    background: colors.backgroundContrast
  }
});

class ViewerCard extends React.Component {

  state = {
    tools: [{
      name: 'WindowLevel',
      display: 'Level',
    }],
    processedOpacity: 50,
  }

  handleReset = () => {
  }

  handleChangeTool = tool => { }


  handleSlide = (value) => this.setState({ processedOpacity: value });

  getDWVs = (classes, imageData) => {
    const { tools, processedOpacity } = this.state;
    if (imageData !== null) {
      const { id, original, processed, type } = imageData;
      return (
        <React.Fragment>
          <DWV
            key={id}
            opacity={1}
            tools={tools}
            data={original}
            type={type}
          />
        </React.Fragment>
      );
    }
    return <div className={classes.empty} />
  }

  render() {
    const { processedOpacity } = this.state;
    const { classes, state } = this.props;

    return (
      <Card className={classes.card}>
        <div className={classes.viewContainer}>
          {this.getDWVs(classes, state.imageData)}
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(ViewerCard);