import React, { Component } from 'react';
import dwv from 'dwv';

import { withStyles } from '@material-ui/core/styles';
import { uuid } from '../../constants';

import './DWV.css';

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  layerContainer: {
  }
});

class DWV extends Component {

  state = {
    name: 'dwv' + uuid(),
    loadProgress: 0,
    dataLoaded: false,
    dwvApp: null,
  }

  componentDidMount() {
    const { name } = this.state;
    const { tools } = this.props;
    dwv.gui.getElement = dwv.gui.base.getElement;
    dwv.gui.displayProgress = (percent) => { };
    const app = new dwv.App();
    app.init({
      containerDivId: name,
    });
    this.setState({ dwvApp: app }, () => this.loadImage());
  }

  loadImage = () => {
    const { data, type } = this.props;
    if (type === 'dcm') {
      if (this.state.dwvApp) {
        this.state.dwvApp.loadImageObject([{ name: "", filename: "", data }]);
      }
    } else if (type === 'png') {
      this.setupImage(this.viewElement, data);
    }
  }

  handleReset = () => {
    if (this.state.dwvApp) {
      this.state.dwvApp.onDisplayReset();
    }
  }

  handleChangeTool = tool => {
    if (this.state.dwvApp) {
      this.setState({ selectedTool: tool });
      this.state.dwvApp.onChangeTool({ currentTarget: { value: tool } });
    }
  }

  render() {
    const { name } = this.state;
    const { classes, opacity } = this.props;
    return (
      <div
        id={name}
        style={{ opacity }}
        className={classes.root}>
        <div className="layerContainer">
          <canvas className="imageLayer"></canvas>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(DWV);
