import React, { Component } from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Logo from './Logo';
import UploadCard from './UploadCard';
import ViewerCard from './ViewerCard';
import Notification from './Notification';
import ProgressBar from './ProgressBar';
import { Radar } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';

import { post } from '../requests';
import { theme, width } from '../constants';

const styles = {
  root: {
    textAlign: 'center',
  },
  cards: {
    margin: '20px auto',
    maxWidth: width,
  },
  plot: {
    margin: '50px 20px'
  },
  cardplot: {
    backgroundColor: 'white',
    margin: '20px auto'
  }
};

const b64ToArrayBuffer = (base64) => {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
const createAndDownloadFile = (body, filename, extension = 'dcm') => {
  const blob = new Blob([body]);
  const fileName = `${filename}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

class App extends Component {
  state = {
    imageData: null,
    notification: null,
    processing: false,
    plot: {
      labels: ['Atelectasis', 'Cardiomegaly', 'Effusion', 'Pneumonia', 'Pneumothorax', 'Consolidation', 'Edema', 'Emphysema', 'Pleural Thickening'],
      datasets: [
        {
          label: 'Flag',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          pointBackgroundColor: 'rgba(255,99,132,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(255,99,132,1)',
          borderWidth: '2',
          data: [0, 0, 0, 0, 0]
        }, {
          label: 'Probability',
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgba(255, 159, 64, 1)',
          pointBackgroundColor: 'rgba(255, 159, 64, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: '2',
          data: [0, 0, 0, 0, 0]
        },
        {
          label: 'Threshold',
          backgroundColor: 'rgba(179,181,198,0)',
          borderColor: 'rgba(179,181,198,1)',
          pointBackgroundColor: 'rgba(179,181,198,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          borderDash: [5,5],
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          borderWidth: '2',
          pointRadius: 0,
          data: [0.14855360984802246,0.028781209141016006,0.1916312426328659,0.01370981801301241,0.02222529798746109,0.04654219374060631,0.037153564393520355,0.004892525263130665,0.019269511103630066]
        }
      ]
    }
  }

  update = (key, val) => this.setState({ [key]: val });

  request = (path, options, cb) => {
    if (path === 'process' && options) {
      this.setState({ processing: false });
      const reader = new FileReader();
      reader.readAsDataURL(options);
      this.setState({ processing: true });
      reader.onload = () => post(
        path, {}, { 'image': reader.result }, (result, success) => {
          if (success) {
            this.setState((previousState) => {
              return {
                plot: {
                  ...previousState.plot,
                  datasets: [{
                    ...previousState.plot.datasets[0],
                    data: result.data.flagData
                  }, 
                  {
                    ...previousState.plot.datasets[1],
                    data: result.data.probabilityData
                  },
                  {
                    ...previousState.plot.datasets[2],
                    data: result.data.thresholdData
                  }],
                  labels: result.data.labels,
                },
                notification: ['Image processed', true],
                processing: false
              }
            });
          } else {
            this.setState({
              notification: ['Error processing image', false],
              processing: false
            });
          }
        });
    }
  }

  render() {
    const { classes } = this.props;
    const { imageData, processing } = this.state;
    return (
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        {processing && <ProgressBar update={this.update} />}
        <Logo />
        <div className={classes.cards}>
          {
            imageData ?
              <React.Fragment>
                <ViewerCard
                  state={this.state}
                  update={this.update}
                  request={this.request} />
                <Card className={classes.cardplot}>
                  <div className={classes.plot}>
                    <Radar data={this.state.plot}/>
                  </div>
                </Card>
                <UploadCard
                  state={this.state}
                  update={this.update}
                  request={this.request}
                />
              </React.Fragment>
              : <UploadCard
                state={this.state}
                update={this.update}
                request={this.request}
              />
          }
        </div>
        <Notification
          state={this.state}
          update={this.update}
        />
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
